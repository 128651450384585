<template>
  <div class="">
    <van-nav-bar left-arrow :title="$t('pur.详情')" fixed @click-left="$router.go(-1)" />
    <van-collapse v-model="activeNames" accordion style="margin-top: 55px">
      <div class="splitTitle">{{ $t('pur.基本信息') }}</div>
      <van-collapse-item :title="$t('pur.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('pur.采购需求单位') }}</h5>
          <p>{{ this.ruleForm.purDemandOrg }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备案编号') }}</h5>
          <p>{{ this.ruleForm.recordCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商名称') }}</h5>
          <p>{{ this.ruleForm.supplierName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商准入等级') }}</h5>
          <p>{{ this.ruleForm.supplierLevel }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否授信') }}</h5>
          <p>{{ this.ruleForm.isCredit | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.授信币别') }}</h5>
          <p>{{ this.ruleForm.creditCurrency }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.授信金额') }}</h5>
          <p>{{ this.ruleForm.creditAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.贸易国别') }}</h5>
          <p>{{ this.ruleForm.tradeCountry }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.业务负责人') }}</h5>
          <p>{{ this.ruleForm.bizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购币种') }}</h5>
          <p>{{ this.ruleForm.purCurrency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计年采购总金额') }}</h5>
          <p>{{ this.ruleForm.totalPurAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.生效日期') }}</h5>
          <p>{{ this.ruleForm.effectiveDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.失效日期') }}</h5>
          <p>{{ this.ruleForm.expirationDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.审批状态') }}</h5>
          <p>{{ this.ruleForm.orderStatus | setDict('PUR_RECORD_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.单据状态') }}</h5>
          <p>{{ this.ruleForm.status | setDict('PUR_RECORD_ORDER_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.已采购金额') }}</h5>
          <p>{{ this.ruleForm.purAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.剩余采购额度') }}</h5>
          <p>{{ remainPurAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商结算方式') }}</h5>
          <p>{{ this.ruleForm.supplierPaymentMethod | setDict('SUPPLIER_PAYMENT_METHOD') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.自采原因') }}</h5>
          <p>{{ this.ruleForm.selfPurReasons }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备注') }}</h5>
          <p>{{ this.ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.备案商品信息')" name="2">
        <div v-if="!this.ruleForm.purRecordProdInfoLineList || !this.ruleForm.purRecordProdInfoLineList.length">{{ $t('pur.无') }}</div>
        <div v-for="(item, index) in this.ruleForm.purRecordProdInfoLineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.二级商品类别') }}</h5>
            <p>{{ item.commodityCode }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.二级商品类别名称') }}</h5>
            <p>{{ item.commodityCategory }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.商品名称') }}</h5>
            <p>{{ item.commodityName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.备注') }}</h5>
            <p>{{ item.remark }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.系统信息')" name="3">
        <div class="items">
          <h5>{{ $t('pur.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>

    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';

export default {
  components: {
    myHistoryPanel,
    myApproval
  },
  data() {
    return {
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'purRecordOther',
      processInstanceName: '其他采购备案',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        // 基础数据
        id: '',
        orgId: '',
        deptId: null,
        deptName: '',
        orderStatus: '', // 审批状态
        processInstanceId: '',
        createBy: null, // 创建人id
        createByName: '',
        createTime: '', // 申请日期
        lastmodifiedTime: '',
        version: 0,
        isOldVersion: 'N',
        purRecordType: 'OTHER', // 采购备案类别(国贸DOM;其他OTHER)
        // 业务数据
        // 国贸备案基本信息
        purDemandOrgId: '', // 采购需求单位id
        purDemandOrg: '', // 采购需求单位
        recordCode: '', // 备案编号
        supplierId: null, // 供应商Id
        supplierName: '', // 供应商名称
        bizUserId: null, // 业务负责人id
        bizUserName: '', // 业务负责人
        effectiveDate: '', // 生效日期
        status: '', // 单据状态
        remark: '', // 备注
        // 其他备案基本信息（包含了国贸备案基本信息）
        supplierLevel: '', // 供应商准入等级
        isCredit: '', // 是否授信
        creditCurrency: '', // 授信币别
        creditAmount: '', // 授信金额
        tradeCountry: '', // 贸易国别
        purCurrency: '', // 采购币种
        totalPurAmount: '', // 预计年采购总金额
        expirationDate: '', // 失效日期
        purAmount: '', // 已采购金额
        remainPurAmount: '', // 剩余采购额度
        supplierPaymentMethod: '', // 供应商付款方式
        selfPurReasons: '', // 自采原因
        // 备案商品信息表
        purRecordProdInfoLineList: [] // 备案商品信息
      },
      historyList: [],
    };
  },
  computed: {
    // 剩余采购额度
    remainPurAmount () {
      this.ruleForm.remainPurAmount = parseFloat(this.ruleForm.totalPurAmount ? this.ruleForm.totalPurAmount : 0) - parseFloat(this.ruleForm.purAmount ? this.ruleForm.purAmount : 0);
      return this.ruleForm.remainPurAmount;
    }
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    findHistory() {
      const url = '/microarch/pur/purDomesticTradeHeader/list';
      let fixedQuery = [
        {
          field: 'originalContractNo',
          opt: '=',
          value: this.ruleForm.originalContractNo,
          assemble: 'and'
        },
        {
          field: 'isOldVersion',
          opt: '=',
          value: 'Y',
          assemble: 'and'
        }
      ];
      this.$myHttp({
        method: 'POST',
        url: url,
        data: {
          where: fixedQuery
        }
      }).then(res => {
        var data = res.data.rows;
        this.historyList = data;
      });
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/purRecordOther/detailInfo',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.findHistory();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('pur.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/purRecordOther/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('pur.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/purRecordOther/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/pur/purRecordOther/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('pur.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/purRecordOther/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
